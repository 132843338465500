
import {defineComponent, computed, onMounted, ref} from "vue";
import { useI18n } from "vue-i18n";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { Actions } from "@/store/enums/StoreEnums";
import AppCore from "@/core/appcore/AppCore";

export default defineComponent({
    name: "kt-user-menu",
    components: {},
    setup() {

      onMounted(() => {
        userInfo.value = AppCore.prsCore_User.getUserInfo();
        console.log(userInfo.value);
      });

        //const userInfo = ref({Username: (AppCore.prsCore_User.getUserInfo().Username), Email:(AppCore.prsCore_User.getUserInfo().Email)});
        const userInfo = ref({});
        const router = useRouter();
        const i18n = useI18n();
        const store = useStore();





        i18n.locale.value = localStorage.getItem("lang")
            ? (localStorage.getItem("lang") as string)
            : "en";

        const countries = {
            en: {
                flag: "media/flags/united-states.svg",
                name: "English",
            },
            es: {
                flag: "media/flags/spain.svg",
                name: "Spanish",
            },
            de: {
                flag: "media/flags/germany.svg",
                name: "German",
            },
            ja: {
                flag: "media/flags/japan.svg",
                name: "Japanese",
            },
            fr: {
                flag: "media/flags/france.svg",
                name: "French",
            },
        };

        const signOut = () => {
            store
                .dispatch(Actions.LOGOUT)
                .then(() => router.push({ name: "sign-in" }));
        };

        const setLang = (lang) => {
            localStorage.setItem("lang", lang);
            i18n.locale.value = lang;
        };

        const currentLanguage = (lang) => {
            return i18n.locale.value === lang;
        };

        const currentLangugeLocale = computed(() => {
            return countries[i18n.locale.value];
        });

        return {
            signOut,
            setLang,
            currentLanguage,
            currentLangugeLocale,
            countries,
            userInfo,
        };
    },
});
